<template>
    <div class="wrap">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/list',query:{type:type} }" v-show="shopName">
                    {{languageName=='cn'?oneTypeArrObj.cn_title:''}}
                    {{languageName=='en'?oneTypeArrObj.en_title:''}}
                    {{languageName=='it'?oneTypeArrObj.it_title:''}}
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="navcolor">
                        {{languageName=='cn'?shopInfoObj.cn_name:''}}
                        {{languageName=='en'?shopInfoObj.en_name:''}}
                        {{languageName=='it'?shopInfoObj.it_name:''}}
                    </span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <!-- pc布局【商品展示】 -->
        <b-container class="pc-navbox">
            <b-row>
                <b-col class="pc-goodinfoimg">
                    <div class="pc-good_maximg">
                        <div class="pc-good-item"><img :src="shopdataImg[imgIndex]" alt=""></div>
                    </div>
                    <div class="pc-good_minimg">
                        <div class="pc-good-item" @click="getImg(index)" :class="imgIndex==index?'pc-cur':''" v-for="(item,index) in shopdataImg" :key="index">
                            <img :src="item" alt="">
                        </div>
                    </div>
                </b-col>
                <b-col class="pc-goodinfo">
                    <div class="">
                        <div class="pc-title">
                            {{languageName=='cn'?shopInfoObj.cn_name:''}}
                            {{languageName=='en'?shopInfoObj.en_name:''}}
                            {{languageName=='it'?shopInfoObj.it_name:''}}
                        </div>
                        <div class="pc-money">{{$t('message.m')}}{{shopInfoObj.moneyOne}}</div>
                        <div class="pc-shopinfo">
                            <div class="pc-txt">
                                {{languageName=='cn'?shopInfoObj.cn_profile:''}}
                                {{languageName=='en'?shopInfoObj.en_profile:''}}
                                {{languageName=='it'?shopInfoObj.it_profile:''}}
                            </div>
                            <!-- <div class="pc-txt">兼容 Pod：RELX Pod Pro</div> -->
                            <!-- <div class="pc-item-start">
                                <my-rate :score="rateNum" :disabled="true"/>
                                <span class="stx">18条评论</span>
                            </div> -->
                        </div>
                        <div class="pc-tit_tab">{{$t('message.color')}}</div>
                        <div class="pc-tit_span">
                            <span class="pc-span" v-for="(v,index) in tablistcolor" :key="index" :class="shopcolorindex==index?'pc-cur':''" @click="getShopColor(v,index)">
                            {{languageName=='cn'?v.cn_spect_name:''}}
                            {{languageName=='en'?v.en_spect_name:''}}
                            {{languageName=='it'?v.it_spect_name:''}}
                            </span>
                        </div>      
                        <div class="pc-tit_tab">{{$t('message.num')}}</div>
                        <div class="pc-tit_tab_btn">
                            <span class="pc-leftbtn" @click="getRemoveItem()"><span class="el-icon-minus"></span></span>
                            <span class="pc-lefttxt">{{shopNumber}}</span>
                            <span class="pc-leftbtn" @click="getAddItem()"><span class="el-icon-plus"></span></span>
                        </div>
                        <div class="pc-btn">
                            <div class="pc-good_btn pc-caradd" @click="getAddShopCar(shopInfoObj)">{{$t('message.addcar')}}</div>
                            <div class="pc-good_btn pc-buypush" @click="getPayNow(shopInfoObj)">{{$t('message.letpay')}}</div>
                        </div>
                        <div class="pc-paytype">{{$t('message.paytype')}}：
                            <span class="pc-payicon pc-zfb"></span>
                            <span class="pc-payicon pc-wx"></span>
                            <span class="pc-payicon pc-visa"></span>
                            <span class="pc-payicon pc-load"></span>
                        </div>
                        <input type="hidden" id="ins"/>
                        <div class="pc-paytype">{{$t('message.shareto')}}：&nbsp;
                            <span class="pc-shareicon pc-ins" @click="getShare('ins','web')"></span>
                            <span class="pc-shareicon pc-dy" @click="getShare('tiktok','web')"></span>
                            <span class="pc-shareicon pc-wxshare" @click="getShare('weixin','web')"></span>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <!-- wap布局【商品展示】 -->
        <b-container class="wap-navbox">
            <b-row>
                <b-col class="wap-goodinfoimg">
                    <div class="wap-good_maximg">
                        <div class="wap-good-item"><img :src="shopdataImg[imgIndex]" alt=""></div>
                    </div>
                    <div class="wap-good_minimg">
                        <div class="wap-good-item" @click="getImg(index)" :class="imgIndex==index?'wap-cur':''" v-for="(item,index) in shopdataImg" :key="index">
                            <img :src="item" alt="">
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="wap-goodinfo">
                    <div class="">
                        <div class="wap-title">
                            {{languageName=='cn'?shopInfoObj.cn_name:''}}
                            {{languageName=='en'?shopInfoObj.en_name:''}}
                            {{languageName=='it'?shopInfoObj.it_name:''}}
                        </div>
                        <div class="wap-money">{{$t('message.m')}}{{shopInfoObj.moneyOne}}</div>
                        <div class="wap-shopinfo">
                            <div class="wap-txt">
                                {{languageName=='cn'?shopInfoObj.cn_profile:''}}
                                {{languageName=='en'?shopInfoObj.en_profile:''}}
                                {{languageName=='it'?shopInfoObj.it_profile:''}}    
                            </div>
                            <!-- <div class="wap-txt">兼容 Pod：RELX Pod Pro</div> -->
                            <!-- <div class="wap-item-start">
                                <my-rate :score="rateNum" :disabled="true"/>
                                <span class="wap-stx">18条评论</span>
                            </div> -->
                        </div>
                        <div class="wap-tit_tab">{{$t('message.color')}}</div>
                        <div class="wap-tit_span">
                            <span class="wap-span" v-for="(v,index) in tablistcolor" :key="index" :class="shopcolorindex==index?'wap-cur':''" @click="getShopColor(v,index)">
                                {{languageName=='cn'?v.cn_spect_name:''}}
                                {{languageName=='en'?v.en_spect_name:''}}
                                {{languageName=='it'?v.it_spect_name:''}}
                            </span>
                        </div>      
                        <div class="wap-tit_tab">{{$t('message.num')}}</div>
                        <div class="wap-tit_tab_btn">
                            <span class="wap-leftbtn" @click="getRemoveItem()"><span class="el-icon-minus"></span></span>
                            <span class="wap-lefttxt">{{shopNumber}}</span>
                            <span class="wap-leftbtn" @click="getAddItem()"><span class="el-icon-plus"></span></span>
                        </div>
                        <div class="wap-btn">
                            <div class="wap-good_btn wap-caradd" @click="getAddShopCar(shopInfoObj)">{{$t('message.addcar')}}</div>
                            <div class="wap-good_btn wap-buypush" @click="getPayNow(shopInfoObj)">{{$t('message.letpay')}}</div>
                        </div>
                        <div class="wap-paytype">{{$t('message.paytype')}}：
                            <span class="wap-payicon wap-zfb"></span>
                            <span class="wap-payicon wap-wx"></span>
                            <span class="wap-payicon wap-visa"></span>
                            <span class="wap-payicon wap-load"></span>
                        </div>
                        <div class="wap-paytype">{{$t('message.shareto')}}：&nbsp;
                            <span class="wap-shareicon wap-ins" @click="getShare('ins','mobile')"></span>
                            <span class="wap-shareicon wap-dy" @click="getShare('tiktok','mobile')"></span>
                            <span class="wap-shareicon wap-wxshare" @click="getShare('weixin','mobile')"></span>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <!-- pc和wap布局【商品介绍和评价】 -->
        <Affix>
            <div class="tabctenter">
                <b-container>
                    <div class="tab">
                        <a href="javascript:void(0)" ><div class="titem" :class="destab==0?'cur':''" @click="getDes(0)">{{$t('message.shoptxt')}}</div></a>
                        <a href="javascript:void(0)"><div class="titem" :class="destab==1?'cur':''" @click="getDes(1)">{{$t('message.commentxt')}}</div></a>
                    </div>
                </b-container>
            </div>
        </Affix>
        <!-- pc和wap布局【商品详情图片】 -->
        <div class="goodShow" id="basic_usage" >
            <div class="img-banner" v-show="languageName=='cn'" v-html="shopInfoObj.cn_content"></div>
            <div class="img-banner" v-show="languageName=='en'" v-html="shopInfoObj.en_content"></div>
            <div class="img-banner" v-show="languageName=='it'" v-html="shopInfoObj.it_content"></div>
        </div>
        <!-- pc布局【顾客评论】 -->
        <b-container class="pc-article" id="static_position">
            <div class="pc-comme">
                <span class="pc-stx-labe">{{$t('message.ucom')}}&nbsp;</span>
                <my-rate :score="scoreStart" :disabled="true"/>
                <span class="pc-stx">{{common_avg}}</span>
            </div>
            <div class="pc-commetab">
                <!-- <div class="pc-tabname" :class="commentindex==0?'pc-cur':''" @click="getComment(0)">全部评价(0)</div> -->
                <div class="pc-tabname" :class="commentindex==item.id?'pc-cur':''" v-for="(item,index) in typelist" :key="index" @click="getComment(item)">{{item.name}}({{item.count}})</div>
            </div>
            <div class="pc-article-group" v-for="(item,index) in listdata" :key="index">
                <div class="pc-imagetext">
                    <div class="pc-images">
                        <div class="pc-name">{{item.user_name}}</div>
                        <div class="pc-time">{{item.create_time}}</div>
                    </div>
                    <div class="pc-txtcontent">
                        <div class="pc-commes">
                            <my-rate :score="item.score" :disabled="true"/>
                        </div>
                        <div class="pc-text">{{item.content}}</div>
                    </div>
                    
                </div>
            </div>
            <div class="pc-page" v-show="totalpage>rows">
                <b-pagination :prev-text="$t('message.prev')" :next-text="$t('message.next')" v-model="currentPage" :total-rows="rows" :per-page="totalpage" aria-controls="my-table"></b-pagination>    
            </div>
        </b-container>
        <!-- wap布局【商品展示】 -->
        <b-container class="wap-article" id="static_positions">
            <div class="wap-comme">{{$t('message.ucom')}}&nbsp;
                <my-rate :score="scoreStart" :disabled="true"/>
                <span class="wap-stx">{{common_avg}}</span>
            </div>
            <div class="wap-commetab">
                <!-- <div class="wap-tabname" :class="commentindex==0?'wap-cur':''" @click="getComment(0)">全部评价(13)</div> -->
                <div class="wap-tabname" :class="commentindex==item.id?'pc-cur':''" v-for="(item,index) in typelist" :key="index" @click="getComment(item)">{{item.name}}({{item.count}})</div>
                <!-- <div class="wap-tabname" :class="commentindex==1?'wap-cur':''" @click="getComment(1)">好评(8)</div>
                <div class="wap-tabname" :class="commentindex==2?'wap-cur':''" @click="getComment(2)">中评(2)</div>
                <div class="wap-tabname" :class="commentindex==3?'wap-cur':''" @click="getComment(3)">差评(3)</div> -->
            </div>
            <div class="wap-article-group" v-for="(item,index) in listdata" :key="index">
                <div class="wap-imagetext">
                    <div class="wap-images">
                        <div class="wap-name">{{item.user_name}}</div>
                        <div class="wap-time">{{item.create_time}}</div>
                    </div>
                    <div class="wap-txtcontent">
                        <div class="wap-commes">
                            <my-rate :score="item.score" :disabled="true"/>
                        </div>
                        <div class="wap-text">{{item.content}}</div>
                    </div>
                </div>
            </div>
        </b-container>
        <!-- 微信二维码弹窗 -->
        <b-modal size="sm" centered v-model="codeqrflag" header-class="justify-content-center" hide-footer :title="$t('message.wcharttitle')" @hidden="getClose">
            <div class="qr_wrap">
                <vue-qr :text="text_url" :size="200"></vue-qr>
            </div>
        </b-modal>
    </div>
</template>
<script>
import vueQr from 'vue-qr';
import ElementUI from 'element-ui';
import {yooz_url,yooz_lang,getLocalItem,delLocalItem} from '@/untils/common';
import myRate from '@/components/commons/rates.vue';
export default {
    name:'info',
    inject:['reload'],
    components:{
        myRate,vueQr
    },
    data(){
        return{
            codeqrflag:false,
            text_url:'',
            token:'',
            login_user_info:{},
            destab:0,
            base_url_img:yooz_url,
            languageName:'',
            languageObj:{},
            shopid:'',
            cn_html:'',
            // rateNum:4,
            scoreStart:0,
            currentPage:1,
            totalpage:0,
            rows:0,
            shopcolorindex:0,
            goodspectid:0,
            tablistcolor:[],
            typelist:[],
            common_avg :0,
            common_count :0,
            imgIndex:0,
            shopName:'',
            type:0,
            shopdataImg:[],
            oneTypeArrObj:{},
            shopInfoObj:{
                // {
                //     "id": 39,
                //     "goods_type_id": 23,
                //     "goods_type_two_id": 26,
                //     "goods_name": "苹果",
                //     "goods_name2": "苹果",
                //     "cn_name": null,
                //     "en_name": null,
                //     "it_name": null,
                //     "title": "苹果",
                //     "subtitle": "苹果",
                //     "is_home": 0,
                //     "profile": " 24期花呗免息付款0首付 Apple/苹果iPhone 13 Pro手机5G苹果13Pro手机旗舰店正品国行官方新款全网通官网苹果",
                //     "goods_img": "https://img.alicdn.com/imgextra/i3/2024314280/O1CN01a1XAfZ1hUHrxavHyq_!!2024314280.jpg_430x430q90.jpg",
                //     "goods_imgs": "https://img.alicdn.com/imgextra/i3/2024314280/O1CN01a1XAfZ1hUHrxavHyq_!!2024314280.jpg_430x430q90.jpg,https://img.alicdn.com/imgextra/i1/2024314280/O1CN01GemoNS1hUHs1ZQ306_!!2024314280.jpg_430x430q90.jpg,https://img.alicdn.com/imgextra/i2/2024314280/O1CN01JNvtru1hUHrrVss9I_!!2024314280.jpg_430x430q90.jpg",
                //     "content": "",
                //     "price": "100.00",
                //     "status": 0,
                //     "order_sum": 0,
                //     "create_time": "2022-03-15 20:54:48",
                //     "prime_index": 0,
                //     "fanwei": "豆腐干大师傅感豆腐地法国当时法国地方地法国<img src=\"/static/kindeditor/attached/image/20220315/20220315205416_87571.jpg\" width=\"100%\" alt=\"\" />",
                //     "ganshou": "<img src=\"/static/kindeditor/attached/image/20220315/20220315205427_84210.jpg\" width=\"100%\" alt=\"\" />",
                //     "chengfen": "<img src=\"/static/kindeditor/attached/image/20220315/20220315205438_22749.png\" width=\"100%\" alt=\"\" />",
                //     "use_method": "<img src=\"/static/kindeditor/attached/image/20220315/20220315205445_60609.jpg\" width=\"100%\" alt=\"\" />",
                //     "sort": null,
                //     "cn_price": null,
                //     "en_price": null,
                //     "it_price": null
                // }
            },
            shopNumber:1,
            commentindex:1,
            listdata:[],
            userPople:'',//userPople:'company' 企业用户，数量10+
        }
    },
    watch:{
        searchParams:function() {
            this.getShopInfo();
        },
    },
    computed:{
        searchParams() {
            if(this.$route.query&&this.$route.query.shopid){
                var params = {
                    "id":this.$route.query.shopid,
                    "iscompany":this.userPople=='company'?1:0,//（是否为企业身份 1是0否）
                };
                return params;
            }
        },
        // 二维码地址合成
        qrcodeUrl(){
            let urls = location.href.replace('invite','userenter')
            return urls
        },
        //判断是否是微信浏览器的函数（如果需要完善QQ的分享请判断浏览器环境后自行设置相关配置）
        isWechant() {
            var ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        getDes(index){
            this.destab = index;
            if(index==0){
                var element = document.getElementById("basic_usage");
                element.scrollIntoView();
                element.scrollIntoView(true);
                element.scrollIntoView({block: "start"});
                element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }else{
                var element = document.getElementById("static_position");
                element.scrollIntoView();
                element.scrollIntoView(false);
                element.scrollIntoView({block: "end"});
                element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                var elements = document.getElementById("static_positions");
                elements.scrollIntoView();
                elements.scrollIntoView(true);
                elements.scrollIntoView({block: "start"});
                elements.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
        },
        getImg(index){
            this.imgIndex = index;
        },
        getShopColor(item,index){
            this.shopcolorindex = index;
            this.goodspectid = item.id;
        },
        getComment(item){
            if(item){
                this.commentindex = item.id;
                this.getCommentTypeList(item,1);
            }
        },
        // 关闭弹窗
        getClose(){
            this.codeqrflag = false;
        },
        getShare(type,eq){
            // if(this.token){
                if(type=='weixin'){
                    this.codeqrflag = !this.codeqrflag;
                    var host = location.href;//.split('#')[0]
                    this.text_url = host;
                }else{
                    var host = location.href;//.split('#')[0]
                    this.$copyText(host).then(()=>{
                        ElementUI.Message({
                            message:this.languageObj.copytip,
                            type:'success',
                            center: true,
                            offset:10,
                        });
                    }).catch(()=>{
                        ElementUI.Message({
                            message:this.languageObj.copyfiledtip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                    });
                } 
            // }else{
            //     this.$store.commit('setLoginStatus',true);
            // }
        },
        // 单个商品数量加1
        getAddItem(){
            if(this.userPople=='company'){
                this.shopNumber = this.shopNumber+10
            }else{
                this.shopNumber++;
            }
        },
        // 单个商品数量减1
        getRemoveItem(){
            if(this.shopNumber>1){
                if(this.userPople=='company'){
                    this.shopNumber = this.shopNumber-10
                }else{
                    this.shopNumber--
                }
            }else{
                ElementUI.Message({
                    message:this.languageObj.numbertip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 立即支付（创建订单接口）
        getPayNow(item){
            if(this.token&&item){
                if(this.login_user_info.is_company==1){
                    if(this.login_user_info.cinfo){
                        // 企业用户
                        if(!this.login_user_info.cinfo.company_name){
                            this.$router.push({path:'/uinfo',query:{type:3}});
                        }else{
                            let params = {
                                token:this.token,//（用户token）、
                                goodsid:item.id,//（商品id）、
                                goodsnum:this.shopNumber,//（商品数量）、
                                spectid:this.goodspectid,//（规格id）、
                                country:this.languageName,//（国家编码）
                            }
                            this.$http.api_order_createOrderByGoodsid(params).then((res)=>{
                                if(res){
                                    if(res.code==1){
                                        if(res.data){
                                            this.$router.push({path:'/shopfrom',query:{
                                                orderno:res.data.orderno,
                                                goodsid:item.id,
                                                goodsnum:this.shopNumber,
                                                spectid:this.goodspectid
                                            }});
                                        }
                                    }else if(res.code==-1){
                                        // this.reload();
                                        this.$store.commit('setLoginNameStatus', {});
                                        delLocalItem('login_user_info');
                                        this.$root.Bus.$emit('userInfoClick', {});
                                        this.$store.commit('setLoginStatus',true);
                                        this.$router.push({path:'/'});
                                    }else{
                                        ElementUI.Message({
                                            message:res.message,
                                            type:'error',
                                            center: true,
                                            offset:10,
                                        });
                                        return false;
                                    }
                                }
                            });
                        }
                    }
                }else{
                    // 普通用户
                    if(!this.login_user_info.family_name){
                        this.$router.push({path:'/uinfo',query:{type:1}});
                    }else{
                        let params = {
                            token:this.token,//（用户token）、
                            goodsid:item.id,//（商品id）、
                            goodsnum:this.shopNumber,//（商品数量）、
                            spectid:this.goodspectid,//（规格id）、
                            country:this.languageName,//（国家编码）
                        }
                        this.$http.api_order_createOrderByGoodsid(params).then((res)=>{
                            if(res){
                                if(res.code==1){
                                    if(res.data){
                                        this.$router.push({path:'/shopfrom',query:{
                                            orderno:res.data.orderno,
                                            goodsid:item.id,
                                            goodsnum:this.shopNumber,
                                            spectid:this.goodspectid
                                        }});
                                    }
                                }else if(res.code==-1){
                                    // this.reload();
                                    this.$store.commit('setLoginNameStatus', {});
                                    delLocalItem('login_user_info');
                                    this.$root.Bus.$emit('userInfoClick', {});
                                    this.$store.commit('setLoginStatus',true);
                                    this.$router.push({path:'/'});
                                }else{
                                    ElementUI.Message({
                                        message:res.message,
                                        type:'error',
                                        center: true,
                                        offset:10,
                                    });
                                    return false;
                                }
                            }
                        });
                    }
                }
            }else{
                this.$store.commit('setLoginStatus',true);
            }
        },
        // 加入购物车
        getAddShopCar(item){
            if(this.token&&item){
                if(this.shopNumber>0){
                    let params = {
                        token:this.token,//（用户token）、
                        goods_id:item.id,//（商品id）、
                        goods_no:this.shopNumber,//（商品数量）、
                        is_delete:0,//（是否删除 1是 0否）、
                        is_add:1,//（是否添加 1是 0否）、
                        is_edit:0,//（是否 编辑 1是 0否）
                        goods_spect_id:this.goodspectid,//（产品规格id）
                    }
                    this.$http.api_order_editcart(params).then((res)=>{
                        if(res){
                            if(res.code==1){
                                ElementUI.Message({
                                    message:this.languageObj.carsuccesstip,
                                    type:'success',
                                    center: true,
                                    offset:10,
                                });
                            }else if(res.code==-1){
                                // this.reload();
                                this.$store.commit('setLoginNameStatus', {});
                                delLocalItem('login_user_info');
                                this.$root.Bus.$emit('userInfoClick', {});
                                this.$store.commit('setLoginStatus',true);
                                this.$router.push({path:'/'});
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }else{
                    ElementUI.Message({
                        message:this.languageObj.numbertip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
            }else{
                this.$store.commit('setLoginStatus',true);
            }
        },
        // 商品详情接口
        getShopInfo(){
            this.shopInfoObj = {};
            this.$http.api_goods_shopinfobyid(this.searchParams).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.shopInfoObj = res.data;
                            var srcArrReg = /src=([\'\"]?([^\'\"]*)[\'\"]?)/ig;
                            if(this.languageName=='cn'){
                                let cn_html = res.data.cn_content;
                                if(cn_html){
                                    // cn_html = cn_html.replace(srcArrReg,"src='"+this.base_url_img+"$2"+"'");
                                    // this.shopInfoObj.cn_content = cn_html;
                                    // 解决img图片路径带域名（https）的不用再加域名，不带域名的，加上域名
                                    let imgReg = /<img.*?(?:>|\/>)/gi // 匹配图片中的img标签
                                    let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
                                    let arr = cn_html.match(imgReg)  // 筛选出所有的img
                                    let srcArr = []
                                    if (arr != null) {
                                        for (let i = 0; i < arr.length; i++) {
                                            let src = arr[i].match(srcReg)
                                            var reg=/http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
                                            if(!reg.test(src[1])){
                                                src[1] = this.base_url_img+src[1]
                                                src.input = src.input.replace(srcArrReg,"src='"+this.base_url_img+"$2"+"'");
                                            }
                                            srcArr.push(src.input)
                                        }
                                    }
                                    this.shopInfoObj.cn_content = srcArr.toString();
                                }

                                if(res.data.discounts!=0){
                                    this.shopInfoObj.money = res.data.cn_price*((res.data.discounts)/100);//100-
                                    this.shopInfoObj.moneyOne = this.shopInfoObj.money.toFixed(2);
                                }else{
                                    this.shopInfoObj.money = res.data.cn_price;
                                    this.shopInfoObj.moneyOne = this.shopInfoObj.money;//.toFixed(2);
                                }
                            }else if(this.languageName=='en'){
                                let en_html = res.data.en_content;
                                if(en_html){
                                    // en_html = en_html.replace(srcArrReg,"src='"+this.base_url_img+"$2"+"'");
                                    // this.shopInfoObj.en_content = en_html;
                                    // 解决img图片路径带域名（https）的不用再加域名，不带域名的，加上域名
                                    let imgReg = /<img.*?(?:>|\/>)/gi // 匹配图片中的img标签
                                    let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
                                    let arr = en_html.match(imgReg)  // 筛选出所有的img
                                    let srcArr = []
                                    if (arr != null) {
                                        for (let i = 0; i < arr.length; i++) {
                                            let src = arr[i].match(srcReg)
                                            var reg=/http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
                                            if(!reg.test(src[1])){
                                                src[1] = this.base_url_img+src[1]
                                                src.input = src.input.replace(srcArrReg,"src='"+this.base_url_img+"$2"+"'");
                                            }
                                            srcArr.push(src.input)
                                        }
                                    }
                                    this.shopInfoObj.en_content = srcArr.toString();
                                }

                                if(res.data.discounts!=0){
                                    this.shopInfoObj.money = res.data.en_price*((res.data.discounts)/100);//100-
                                    this.shopInfoObj.moneyOne = this.shopInfoObj.money.toFixed(2);
                                }else{
                                    this.shopInfoObj.money = res.data.en_price;
                                    this.shopInfoObj.moneyOne = this.shopInfoObj.money;//.toFixed(2);
                                }
                            }else if(this.languageName=='it'){
                                let it_html = res.data.it_content;
                                if(it_html){
                                    // it_html = it_html.replace(srcArrReg,"src='"+this.base_url_img+"$2"+"'");
                                    // this.shopInfoObj.it_content = it_html;
                                    // 解决img图片路径带域名（https）的不用再加域名，不带域名的，加上域名
                                    let imgReg = /<img.*?(?:>|\/>)/gi // 匹配图片中的img标签
                                    let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
                                    let arr = it_html.match(imgReg)  // 筛选出所有的img
                                    let srcArr = []
                                    if (arr != null) {
                                        for (let i = 0; i < arr.length; i++) {
                                            let src = arr[i].match(srcReg)
                                            var reg=/http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
                                            if(!reg.test(src[1])){
                                                src[1] = this.base_url_img+src[1]
                                                src.input = src.input.replace(srcArrReg,"src='"+this.base_url_img+"$2"+"'");
                                            }
                                            srcArr.push(src.input)
                                        }
                                    }
                                    this.shopInfoObj.it_content = srcArr.toString();
                                }

                                if(res.data.discounts!=0){
                                    this.shopInfoObj.money = res.data.it_price*((res.data.discounts)/100);//100-
                                    this.shopInfoObj.moneyOne = this.shopInfoObj.money.toFixed(2);
                                }else{
                                    this.shopInfoObj.money = res.data.it_price;
                                    this.shopInfoObj.moneyOne = this.shopInfoObj.money;//.toFixed(2);
                                }
                            }
                            // 商品图片
                            let goodsImgs = res.data.goods_imgs.split(',');
                            this.shopdataImg = goodsImgs;
                            // 商品规格
                            this.$http.api_goods_shopgsinfobyid(this.searchParams).then((response)=>{
                                if(response){
                                    if(response.code==1){
                                        if(response.data){
                                            this.tablistcolor = response.data;
                                            this.goodspectid = response.data[0].id;
                                        }
                                    }else{
                                        ElementUI.Message({
                                            message:response.message,
                                            type:'error',
                                            center: true,
                                            offset:10,
                                        });
                                        return false;
                                    }
                                }
                            });
                            //根据id获取评价的类型
                            this.getCommentTpye(res.data.id);
                        }else{
                            this.shopInfoObj = {};
                        }
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        return false;
                    }
                }
            });
        },
        getCommentTpye(id){
            this.$http.api_order_getfeedbackbygoodsidtypelist({
                goodsid:id,
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            var firstlistobj = res.data.typelist;
                            var firstlistarr = [];
                            for(let i in firstlistobj) {
                                firstlistarr.push ({
                                    id:i,
                                    count:firstlistobj[i].count,
                                    name:firstlistobj[i].name,
                                    value:firstlistobj[i].value,
                                });
                            }
                            this.typelist = firstlistarr;
                            if(firstlistarr.length>0){
                                this.getCommentTypeList(firstlistarr[0],1);
                            }
                            this.common_avg = res.data.avg;
                            this.scoreStart = parseInt(res.data.avg);
                            this.common_count = res.data.count;
                        }
                    }else{
                        this.typelist = [];
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        getCommentTypeList(item,page){
            this.listdata = [];
            this.$http.api_order_getlistbygoodsidtype({
                goodsid:this.shopid,
                type:item.id,
                page:page,
                size:this.size
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        this.listdata = res.data.list;
                        this.totalpage = res.data.totalpage;
                        this.rows = res.data.count;
                    }else{
                        // ElementUI.Message({
                        //     message:res.message,
                        //     type:'error',
                        //     center: true,
                        //     offset:10,
                        // });
                        return false;
                    }
                }
            })
        },
        getNavShopList(){
            this.$http.api_goods_levelone().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.oneTypeArrObj = res.data[this.type];
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
    },
    mounted(){
        // if (this.isWechant) {
        //     this.getWxConfig();
        // }
        this.getShopInfo();
        
        
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "carsuccesstip":"加入购物车成功",
                "numbertip":"数量低于范围",
                "copytip":"复制成功",
                "copyfiledtip":"复制失败",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "carsuccesstip":"successfully Added to Cart",
                "numbertip":"Quantity is below the range",
                "copytip":"Copy succeeded",
                "copyfiledtip":"copy failed",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "carsuccesstip":'Aggiunto al carrello',
                "numbertip":"Non e' abbastanza!",
                "copytip":"Copia riuscita",
                "copyfiledtip":"copia non riuscita",
            }
        }
        let login_user_info = this.$store.state.data.loginNameStatus.family_name?this.$store.state.data.loginNameStatus:(getLocalItem('login_user_info')?getLocalItem('login_user_info'):null);
        if(login_user_info){
            this.login_user_info = login_user_info;
            // 再次判断是否是企业用户(是否企业身份 1是 0否)
            if(login_user_info.is_company==1){
                this.userPople='company';
                this.shopNumber=10
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
        }
        let routes = this.$route.query;
        if(routes){
            this.shopid = routes.shopid;
            if(routes.type){
                this.type = routes.type;
                this.getNavShopList();
            }
            if(routes.shopName){
                this.shopName = routes.shopName;
            }
        }
        
    }
}
</script>
<style lang="scss" scoped>
.navtab{
    padding:20px 10px 10px;
    
}
.navcolor{
    color: #95959E;
}
.tabctenter{
    padding:0 10px 0;
    text-align: center;
    background: #fff;
    .tab{
        padding:10px;
        a{
            color: #222;
            .titem{
                cursor: pointer;
                display: inline-block;
                font-size:16px;
                padding:0 5px 5px;
                margin: 0 20px;
            }
            .cur{
                border-bottom:2px solid #222;
            }
        }
        
    }
}
.qr_wrap{
    img{
        width:100%;
    }
}
.pc-navbox{
    margin-top: 20px;
    border-radius:10px;
    padding:0px 0 20px;
    .row{
        margin:0;
        padding: 0;
    }
    .pc-goodinfoimg{
        width:40%;
        .pc-good_maximg{
            height:400px;
            background: #fff;
            border-radius:10px;
            .pc-good-item{
                width:75%;
                height:100%;
                margin:0 auto;
                cursor: pointer;
                img{
                    cursor: pointer;
                    width: 100%;
                    height:100%;
                }
            }
        }
        .pc-good_minimg{
            height:100px;
            margin-top:20px;
            border-radius:10px;
            display: flex;
            -webkit-box-orient: horizontal;
            .pc-good-item{
                width:22%;
                height:100%;
                margin:0 2%;
                cursor: pointer;
                img{
                    cursor: pointer;
                    width: 100%;
                    height:100%;
                }
            }
            .pc-cur{
                border: 1px solid #1f73b7;
            }
        }
    }
    .pc-goodinfo{
        width: 60%;
        .pc-title{
            font-size:30px;
            font-weight: bold;
        }
        .pc-money{
            font-size:30px;
            color: #bf0000;
            font-weight: bold;
            padding:2px 0 5px;
            .pc-moneybefor{
                color: #95959e;
                text-decoration:line-through
            }
        }
        .pc-shopinfo{
            margin-bottom: 15px;
            padding:0 0 20px 0;
            border-bottom:1px solid #dde0ed;
            .pc-txt{
                color: #95959E;
            }
            .pc-item-start{
                display: flex;
                padding:24px 0 0;
                color: #95959E;
                img{
                    width:24px;
                    margin-right: 2px;
                }
                .pc-stx{
                    display: inline-block;
                    margin-left: 10px;
                    
                }
            }
        }
        .pc-tit_tab{
            
            padding:0px 0 5px;
        }
        .pc-tit_span{
            margin:0 -10px 20px;
            .pc-span{
                display: inline-block;
                cursor: pointer;
                padding:4px 14px;
                border-radius: 3px;
                border:1px solid #95959e;
                margin:10px;
                color: #95959e;
                background: #ffffff;
            }
            .pc-cur{
                color: #1f73b7;
                border:1px solid #1f73b7;
            }
        }
        .pc-tit_tab_btn{
            display: inline-block;
            background: #fff;
            border:1px solid #95959e;
            border-radius: 6px;
            height:35px;
            line-height:35px;
            margin: 10px 0;
            .pc-leftbtn{
                display: inline-block;
                cursor: pointer;
                height:35px;
                padding:0 10px;
                color: #a5a5ad;
            }
            .pc-lefttxt{
                display: inline-block;
                height:34px;
                padding:0 16px;
                border-left:1px solid #95959e;
                border-right:1px solid #95959e;
            }
        }
        .pc-btn{
            display: flex;
            -webkit-box-orient: horizontal;
            width: 100%;
            padding:10px 0;
            .pc-good_btn{
                width:48%;
                cursor: pointer;
                border: 1px solid #2e2e2e;
                border-radius: 6px;
                padding:10px;
                text-align: center;
            }
            .pc-caradd{
                margin:0 2% 0 0;
                color: #6f6f74;
                background: #fff;
            }
            .pc-buypush{
                background: #2e2e2e;
                color: #fff;
            }
        }
        .pc-paytype{
            padding:5px 0;
            vertical-align: middle;
            .pc-payicon{
                display: inline-block;
                width: 30px;
                height:22px;
                margin-right: 10px;
                vertical-align: middle;
            }
            .pc-shareicon{
                display: inline-block;
                cursor: pointer;
                width: 28px;
                height:28px;
                margin:0 5px 0 10px;
                vertical-align: middle;
            }
            .pc-zfb{
                background: url(~@/assets/images/icon_zhifubao.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-wx{
                background: url(~@/assets/images/icon_wchartpay.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-visa{
                background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-load{
                background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-ins{
                background: url(~@/assets/images/icon_Instagram.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-dy{
                background: url(~@/assets/images/icon_tictok.png) no-repeat left top;
                background-size: 100% 100%;
            }
            .pc-wxshare{
                background: url(~@/assets/images/icon_wchart.png) no-repeat left top;
                background-size: 100% 100%;
            }
        }
    }
}
.img-banner{
    // max-height: 500px;
    width: 100%;
    /deep/img{
        width: 100%;
        // max-height: 500px;
    }
}
.goodShow{
    padding:40px 0 0;
    box-sizing: border-box;
    width: 100%;
}
.pc-article{
    padding:40px 20px 20px 20px;
    
    .pc-comme{
        display: flex;
        -webkit-box-orient: horizontal;
        
        padding:20px 20px;
        background: #f4f7fe;
        img{
            width:20px;
            margin-right: 2px;
        }
        .pc-stx-labe{
            font-size:18px;
            margin-top: -2px;
        }
        .pc-stx{
            display: inline-block;
            margin-left: 10px;
            
        }
    }
    .pc-commetab{
        padding: 20px;
        .pc-tabname{
            cursor: pointer;
            display: inline-block;
            padding:4px 10px;
            color: #616166;
            border: 1px solid #616166;
            border-radius:5px;
            margin-right: 20px;
        }
        .pc-cur{
            color: #1f73b7;
            border: 1px solid #1f73b7;
        }
    }
    .pc-article-group{
        padding:10px 0;
        .pc-title{
            font-size:18px;
            line-height: 40px;
        }
        .pc-imagetext{
            display: flex;
            -webkit-box-orient: horizontal;
            .pc-images{
                width: 15%;
                padding-left: 20px;
                .pc-name{
                    
                    padding:0 0 10px;
                }
                .pc-time{
                    color:#95959e ;
                }
            }
            .pc-txtcontent{
                width:85%;
                padding-left: 20px;
                .pc-commes{
                    font-size:18px;
                    vertical-align: middle;
                    padding-bottom: 10px;
                    img{
                        width:20px;
                        margin-right: 2px;
                    }
                }
                .pc-text{
                    color: #616166;
                    line-height: 26px;
                }
            }
        }
        .pc-arttime{
            padding:10px 0;
            .pc-times{text-align: right;}
        }
        border-bottom:1px solid #eee;
    }
    .pc-page{
        display: flex;
        -webkit-box-orient: horizontal;
        justify-content: center;
        padding:20px 0;
    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-navbox{display: none;}
        .wap-article{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-navbox{display: none;}
        .wap-article{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-navbox{display: none;}
        .wap-article{display: none;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-navbox{display: none;}
        .pc-article{display: none;}
        .wap-navbox{
            background: #fff;
            .wap-goodinfoimg{
                width:40%;
                .wap-good_maximg{
                    height:360px;
                    background: #fff;
                    border-radius:10px;
                    .wap-good-item{
                        width:75%;
                        height:100%;
                        margin:0 auto;
                        img{
                            width: 100%;
                            height:100%;
                        }
                    }
                }
                .wap-good_minimg{
                    height:100px;
                    margin-top:20px;
                    border-radius:10px;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-good-item{
                        width:22%;
                        height:100%;
                        margin:0 2%;
                        img{
                            width: 100%;
                            height:100%;
                        }
                    }
                    .wap-cur{
                        border: 1px solid #1f73b7;
                    }
                }
            }
            .wap-goodinfo{
                padding:20px 10px;
                width: 100%;
                .wap-title{
                    font-size:20px;
                    font-weight: bold;
                }
                .wap-money{
                    font-size:20px;
                    color: #bf0000;
                    font-weight: bold;
                    padding:2px 0 5px;
                    .moneybefor{
                        color: #95959e;
                        text-decoration:line-through
                    }
                }
                .wap-shopinfo{
                    margin-bottom: 15px;
                    padding:0 0 20px 0;
                    border-bottom:1px solid #dde0ed;
                    .wap-txt{
                        color: #95959E;
                    }
                    .wap-item-start{
                        display: flex;
                        padding:24px 0 0;
                        color: #95959E;
                        img{
                            width:20px;
                            margin-right: 2px;
                        }
                        .wap-stx{
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                }
                .wap-tit_tab{
                    
                    padding:0px 0 5px;
                }
                .wap-tit_span{
                    margin:0 -10px 20px;
                    .wap-span{
                        display: inline-block;
                        cursor: pointer;
                        padding:4px 14px;
                        border-radius: 3px;
                        border:1px solid #95959e;
                        margin:10px;
                        color: #95959e;
                        background: #ffffff;
                    }
                    .wap-cur{
                        color: #1f73b7;
                        border:1px solid #1f73b7;
                    }
                }
                .wap-tit_tab_btn{
                    display: inline-block;
                    background: #fff;
                    border:1px solid #95959e;
                    border-radius: 6px;
                    height:35px;
                    line-height:35px;
                    margin: 10px 0;
                    .wap-leftbtn{
                        display: inline-block;
                        cursor: pointer;
                        height:35px;
                        padding:0 10px;
                        color: #a5a5ad;
                        
                    }
                    .wap-lefttxt{
                        display: inline-block;
                        height:34px;
                        padding:0 16px;
                        border-left:1px solid #95959e;
                        border-right:1px solid #95959e;
                    }
                }
                .wap-btn{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    width: 100%;
                    padding:10px 0;
                    .wap-good_btn{
                        width:48%;
                        cursor: pointer;
                        border: 1px solid #2e2e2e;
                        border-radius: 6px;
                        padding:8px;
                        
                        text-align: center;
                    }
                    .wap-caradd{
                        margin:0 2% 0 0;
                        color: #6f6f74;
                        background: #fff;
                    }
                    .wap-buypush{
                        background: #2e2e2e;
                        color: #fff;
                    }
                }
                .wap-paytype{
                    padding:5px 0;
                    vertical-align: middle;
                    .wap-payicon{
                        display: inline-block;
                        width: 30px;
                        height:22px;
                        margin-right: 10px;
                        vertical-align: middle;
                    }
                    .wap-shareicon{
                        display: inline-block;
                        cursor: pointer;
                        width: 28px;
                        height:28px;
                        margin:0 5px 0 10px;
                        vertical-align: middle;
                    }
                    .wap-zfb{
                        background: url(~@/assets/images/icon_zhifubao.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-wx{
                        background: url(~@/assets/images/icon_wchartpay.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-visa{
                        background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-load{
                        background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-ins{
                        background: url(~@/assets/images/icon_Instagram.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-dy{
                        background: url(~@/assets/images/icon_tictok.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-wxshare{
                        background: url(~@/assets/images/icon_wchart.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
            }
        }
        .wap-article{
            padding:70px 0 0 0;
            .wap-comme{
                
                padding:10px 10px;
                vertical-align: middle;
                background: #f4f7fe;
                img{
                    width:20px;
                    margin-right: 2px;
                }
                .wap-stx{
                    display: inline-block;
                    margin-left: 10px;
                }
            }
            .wap-commetab{
                padding: 10px 10px 0;
                .wap-tabname{
                    cursor: pointer;
                    display: inline-block;
                    padding:4px 10px;
                    color: #616166;
                    border: 1px solid #616166;
                    border-radius:5px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    
                }
                .wap-cur{
                    color: #1f73b7;
                    border: 1px solid #1f73b7;
                }
            }
            .wap-article-group{
                padding:10px 0;
                width: 100%;
                display: flex;
                -webkit-box-orient: vertical;
                .wap-imagetext{
                    width: 100%;
                    padding:0 10px;
                    .wap-images{
                        width: 100%;
                        .wap-name{
                            
                        }
                        .wap-time{
                            color:#95959e;
                        }
                    }
                    .wap-txtcontent{
                        width:100%;
                        .wap-commes{
                            font-size:18px;
                            vertical-align: middle;
                            padding-bottom: 10px;
                            img{
                                width:20px;
                                margin-right: 2px;
                            }
                        }
                        .wap-text{
                            color: #616166;
                            line-height: 26px;
                        }
                    }
                }
                border-bottom:1px solid #eee;
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-navbox{display: none;}
        .pc-article{display: none;}
        .wap-navbox{
            background: #fff;
            .wap-goodinfoimg{
                width:40%;
                .wap-good_maximg{
                    height:300px;
                    background: #fff;
                    border-radius:10px;
                    .wap-good-item{
                        width:75%;
                        height:100%;
                        margin:0 auto;
                        img{
                            width: 100%;
                            height:100%;
                        }
                    }
                }
                .wap-good_minimg{
                    height:80px;
                    margin-top:20px;
                    border-radius:10px;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    .wap-good-item{
                        width:22%;
                        height:100%;
                        margin:0 2%;
                        img{
                            width: 100%;
                            height:100%;
                        }
                    }
                    .wap-cur{
                        border: 1px solid #1f73b7;
                    }
                }
            }
            .wap-goodinfo{
                padding:20px 10px;
                width: 100%;
                .wap-title{
                    font-size:20px;
                    font-weight: bold;
                }
                .wap-money{
                    font-size:20px;
                    color: #bf0000;
                    font-weight: bold;
                    padding:2px 0 5px;
                    .moneybefor{
                        color: #95959e;
                        text-decoration:line-through
                    }
                }
                .wap-shopinfo{
                    margin-bottom: 15px;
                    padding:0 0 20px 0;
                    border-bottom:1px solid #dde0ed;
                    .wap-txt{
                        color: #95959E;
                    }
                    .wap-item-start{
                        display: flex;
                        padding:24px 0 0;
                        color: #95959E;
                        img{
                            width:20px;
                            margin-right: 2px;
                        }
                        .wap-stx{
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                }
                .wap-tit_tab{
                    
                    padding:0px 0 5px;
                }
                .wap-tit_span{
                    margin:0 -10px 20px;
                    .wap-span{
                        display: inline-block;
                        cursor: pointer;
                        padding:4px 14px;
                        border-radius: 3px;
                        border:1px solid #95959e;
                        margin:10px;
                        color: #95959e;
                        background: #ffffff;
                    }
                    .wap-cur{
                        color: #1f73b7;
                        border:1px solid #1f73b7;
                    }
                }
                .wap-tit_tab_btn{
                    display: inline-block;
                    background: #fff;
                    border:1px solid #95959e;
                    border-radius: 6px;
                    height:35px;
                    line-height:35px;
                    margin: 10px 0;
                    .wap-leftbtn{
                        display: inline-block;
                        cursor: pointer;
                        height:35px;
                        padding:0 10px;
                        color: #a5a5ad;
                        
                    }
                    .wap-lefttxt{
                        display: inline-block;
                        height:34px;
                        padding:0 16px;
                        border-left:1px solid #95959e;
                        border-right:1px solid #95959e;
                    }
                }
                .wap-btn{
                    display: flex;
                    -webkit-box-orient: horizontal;
                    width: 100%;
                    padding:10px 0;
                    .wap-good_btn{
                        width:48%;
                        border: 1px solid #2e2e2e;
                        border-radius: 6px;
                        padding:8px;
                        
                        text-align: center;
                    }
                    .wap-caradd{
                        margin:0 2% 0 0;
                        color: #6f6f74;
                        background: #fff;
                    }
                    .wap-buypush{
                        background: #2e2e2e;
                        color: #fff;
                    }
                }
                .wap-paytype{
                    padding:5px 0;
                    vertical-align: middle;
                    .wap-payicon{
                        display: inline-block;
                        width: 30px;
                        height:22px;
                        margin-right: 10px;
                        vertical-align: middle;
                    }
                    .wap-shareicon{
                        display: inline-block;
                        cursor: pointer;
                        width: 28px;
                        height:28px;
                        margin:0 5px 0 10px;
                        vertical-align: middle;
                    }
                    .wap-zfb{
                        background: url(~@/assets/images/icon_zhifubao.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-wx{
                        background: url(~@/assets/images/icon_wchartpay.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-visa{
                        background: url(~@/assets/images/icon_visa.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-load{
                        background: url(~@/assets/images/icon_mastercard.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-ins{
                        background: url(~@/assets/images/icon_Instagram.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-dy{
                        background: url(~@/assets/images/icon_tictok.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-wxshare{
                        background: url(~@/assets/images/icon_wchart.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
            }
        }
        .wap-article{
            padding:70px 0 0 0;
            .wap-comme{
                
                padding:10px 10px;
                vertical-align: middle;
                background: #f4f7fe;
                img{
                    width:20px;
                    margin-right: 2px;
                }
                .wap-stx{
                    display: inline-block;
                    margin-left: 10px;
                }
            }
            .wap-commetab{
                padding: 10px 10px 0;
                .wap-tabname{
                    cursor: pointer;
                    display: inline-block;
                    padding:4px 10px;
                    color: #616166;
                    border: 1px solid #616166;
                    border-radius:5px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    
                }
                .wap-cur{
                    color: #1f73b7;
                    border: 1px solid #1f73b7;
                }
            }
            .wap-article-group{
                padding:10px 0;
                width: 100%;
                display: flex;
                -webkit-box-orient: vertical;
                .wap-imagetext{
                    width: 100%;
                    padding:0 10px;
                    .wap-images{
                        width: 100%;
                        .wap-name{
                            
                        }
                        .wap-time{
                            color:#95959e;
                        }
                    }
                    .wap-txtcontent{
                        width:100%;
                        .wap-commes{
                            font-size:18px;
                            vertical-align: middle;
                            padding-bottom: 10px;
                            img{
                                width:20px;
                                margin-right: 2px;
                            }
                        }
                        .wap-text{
                            color: #616166;
                            line-height: 26px;
                        }
                    }
                }
                border-bottom:1px solid #eee;
            }
        }
    }
}
</style>